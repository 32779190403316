<template>
  <AuthContainer
    :no-shrink-heading="false"
    :has-error="codeError"
    :loading="isFormSending"
    @submit-form="sendForgotPasswordCode"
    class="form-container"
  >
    <template #title>
      <div class="form-title">
        <h2>Forgot password</h2>
        <p v-if="!isCodeSent">We will send you a password reset code</p>
      </div>
    </template>
    <template #inputs v-if="!isCodeSent">
      <Input
        v-model="form.username.value"
        :error="form.username.error"
        name="username"
        label="Username or email"
        type="text"
        @input="clearError('username', form)"
        @blur="validateField('username', form)"
      />
    </template>
    <template #actions v-if="!isCodeSent">
      <Button class="form-action">Send Code</Button>
    </template>
    <template #error>
      <Error>
        <template #heading>
          Seems there was an issue with sending the code
        </template>
        <template #body>
          Please try again later or contact us at
          <a :href="`mailto:${config.helpEmail}`" class="error__link">
            {{ config.helpEmail }}
          </a>
        </template>
      </Error>
    </template>
    <template #footer>
      <div v-if="isCodeSent">
        <h3>Please check your email!</h3>
        <h4>We have sent a link to reset your password.</h4>
        <Link class="resend" @click="sendForgotPasswordCode">Resend link</Link>
        <Link class="resend ml-5" @click="$emit('login')">Go to Login</Link>
      </div>
    </template>
  </AuthContainer>
</template>

<script>
import { mapActions } from "vuex";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import AuthContainer from "../AuthContainerNew";
import Input from "@/components/common/InputNew";
import Link from "@/components/common/LinkNew";
import Error from "@/components/common/Error";
import config from "../../../../public/config.json";
import Button from "@/components/common/Button";

export default {
  name: "ForgotPassword",
  props: {},
  emits: ["reset-code-sent"],
  components: {
    AuthContainer,
    Input,
    Error,
    Button,
    Link,
  },
  data: () => ({
    config,
    isFormSending: false,
    isCodeSent: false,
    codeError: false,
    form: {
      username: {
        value: "",
        error: "",
        rules: ["required"],
      },
    },
  }),
  methods: {
    ...mapActions(["forgotPassword"]),
    validateField,
    validateForm,
    clearError,
    sendForgotPasswordCode() {
      const isValid = this.validateForm(this.form);
      if (!isValid) return;

      this.isFormSending = true;
      this.forgotPassword(this.form.username.value)
        .then(() => {
          this.isCodeSent = true;
        })
        .catch(() => {
          this.codeError = true;
        })
        .finally(() => {
          this.isFormSending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.resend {
  color: $red;

  &:hover {
    text-decoration: underline;
  }
}

.form-action {
  margin-left: auto;
}

.form-title {
  text-align: center;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 6px;
  }
}

.form-container {
  margin: auto;
}

.form-container {
  margin: 0;
  max-width: unset;
  padding: 16px;

  ::v-deep h2 {
    font-size: 28px;
  }

  ::v-deep .form__title {
    margin-bottom: 16px;
  }

  ::v-deep .form__inputs {
    margin-bottom: 16px;
  }
  .form-actions__wrapper {
    flex-direction: column;
    row-gap: 16px;
  }
}

@media (min-width: $media-sm) {
  .form-container {
    margin: auto;
    max-width: 70%;
    padding: 48px;

    ::v-deep h2 {
      font-size: 48px;
    }

    ::v-deep .form__title {
      margin-bottom: 48px;
    }

    ::v-deep .form-actions__wrapper {
      flex-direction: row;
      row-gap: 16px;
    }

    ::v-deep .form__inputs {
      margin-bottom: 48px;
    }
  }
}
</style>
