<template>
  <div>
    <!--    after the event-->
    <!--    <cpe-credits v-if="currentView === 'qr'" />-->
    <!--        event-->
    <TicketQr />

    <TicketAssociation v-if="!userName" />
  </div>
</template>

<script>
import TicketAssociation from "./TicketAssociation";
import TicketQr from "./TicketQr";
import { mapGetters } from "vuex";
// import CpeCredits from "./cpeCredits";

export default {
  name: "UserTicket",
  props: {},
  components: {
    // CpeCredits,
    TicketQr,
    TicketAssociation,
  },
  computed: {
    ...mapGetters({
      userName: "getUserName",
    }),
  },
  data: () => ({
    currentView: "qr",
  }),
};
</script>

<!--for testing-->
<!--https://dev.arctic-con.com/#/ac2021/ticket/a554d0ab15c16f3626bdfc48125d9fa7e8a403962733db9cb12fb30fd826046326856956701077a6fbbaea2b0dbe05e3456e24c1ecc20fb413c63b8ccc1c36eb-->
