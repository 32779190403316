<template>
  <div class="padding-x">
    <Preloader v-if="loading" :loading="loading" style="margin-top: 10%" />
    <transition name="fade">
      <Error v-show="associationError" class="sign-success">
        <template #heading> {{ errorMsg || "Something went wrong" }}</template>
        <template #body>
          Please try again or contact us at
          <a :href="`mailto:${config.helpEmail}`" class="error__link">
            {{ config.helpEmail }}
          </a>
        </template>
      </Error>
    </transition>

    <div class="show-auth-form" v-if="!showAuthForm">
      <p>
        If you want to link your ticket to your account and access Virtual
        Conference Platform <br />
        please
        <a class="link" @click="showAuthForm = true"> login/sign up </a>
        here
      </p>
    </div>

    <div v-show="!userName && showAuthForm">
      <LoginForm
        v-if="currentForm === 'login'"
        :credsForFilling="credsForFilling"
        @signUp="currentForm = 'sign-up'"
        @forgot="currentForm = 'forgot'"
        :fromSignUp="signUpSuccessMessage"
      />
      <SignUpForm
        v-if="currentForm === 'sign-up'"
        @goLogin="currentForm = 'login'"
        @registered="registeredSuccess"
      />
      <ForgotPassword
        v-if="currentForm === 'forgot'"
        @login="currentForm = 'login'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Preloader from "@/components/common/Preloader";
import Error from "@/components/common/Error";
import LoginForm from "../auth/LoginFormNew";
import SignUpForm from "../auth/SignUpFormNew";
import ForgotPassword from "../auth/forgotPassword/ForgotPasswordNew";
import config from "../../../public/config.json";

export default {
  name: "TicketAssociation",
  props: {},
  components: {
    ForgotPassword,
    Preloader,
    Error,
    SignUpForm,
    LoginForm,
  },
  data: () => ({
    config,
    showAuthForm: false,
    signUpSuccessMessage: false,
    currentForm: "login",
    loading: false,
    credsForFilling: {},
    associationError: false,
    errorMsg: "",
  }),
  computed: {
    ...mapGetters({ userName: "getUserName" }),
  },
  mounted() {
    console.log(this.userName);
    if (this.userName) {
      this.associateTicket();
    }
  },
  watch: {
    userName() {
      this.associateTicket();
    },
  },
  methods: {
    ...mapActions(["GET"]),
    associateTicket() {
      this.associationError = false;
      this.signUpSuccessMessage = false;
      this.loading = true;
      return this.GET({
        authed: true,
        route: `/ticketing/event/${this.$route.params.id}/ticket/${this.$route.params.token}/validate`,
      })
        .then(() => {
          this.$emit("associated");
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.associationError = true;
          this.errorMsg = err.response.data.msg || "";
        });
    },
    registeredSuccess(form) {
      this.signUpSuccessMessage = true;
      this.credsForFilling = form;
      this.currentForm = "login";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.show-auth-form {
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin: 0 15%;

  p {
    padding: 24px;
  }
}

.sign-success {
  padding-top: 108px;
  margin-bottom: -50px;
  text-align: center;
  color: $dark-gray;
  font-size: 22px;

  @media (min-width: $media-sm) {
    padding-top: 140px;
    margin-bottom: -200px;
    font-size: 24px;
  }
}
</style>
