<template>
  <div
    ref="inputContainerRef"
    :class="[
      'input',
      {
        'input--error': error,
        'input--non-empty': hasValue,
      },
    ]"
  >
    <div class="input__wrapper">
      <div class="input__component">
        <component
          v-if="type == 'number'"
          :is="'number'"
          :value.prop="modelValue"
          :placeholder="label"
          :id="name"
          :type="type"
          :rows="isMultiline ? rows : null"
          ref="inputRef"
          class="input__control number"
          v-bind="$attrs"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <component
          v-else-if="type == 'select'"
          :is="'select'"
          :value.prop="modelValue"
          :placeholder="label"
          :id="name"
          ref="inputRef"
          class="input__control select"
          v-bind="$attrs"
          @input="$emit('update:modelValue', $event.target.value)"
        >
          <option value="" selected>{{ label }}</option>
          <option v-for="(option, i) of selectOptions" :key="i">
            {{ option }}
          </option>
        </component>
        <component
          v-else-if="type === 'file'"
          :is="'input'"
          :value.prop="modelValue"
          :placeholder="label"
          :id="name"
          :rows="isMultiline ? rows : null"
          :type="type"
          ref="inputRef"
          class="input__control"
          v-bind="$attrs"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <component
          v-else-if="type != 'select'"
          :is="isMultiline ? 'textarea' : 'input'"
          :value.prop="modelValue"
          :placeholder="label"
          :id="name"
          :rows="isMultiline ? rows : null"
          :type="type"
          ref="inputRef"
          class="input__control"
          v-bind="$attrs"
          @input="$emit('update:modelValue', $event.target.value)"
        />

        <slot name="inputAction" />
      </div>
      <label :for="name" class="input__label">
        {{ label }}
      </label>
    </div>
    <transition name="fade">
      <div v-if="error" class="input__error">
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "CInput",

  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    isMultiline: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
      required: true,
    },
    rows: {
      type: [String, Number],
      default: 1,
    },
    type: {
      type: String,
      default: "text",
      required: false,
    },
    selectOptions: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    hasValue() {
      return !!this.modelValue.length;
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.autoResize();
      },
      immediate: true,
    },
  },
  methods: {
    autoResize() {
      if (this.isMultiline) {
        const elem = document.getElementById(this.name);
        if (elem) {
          const offset = elem.offsetHeight - elem.clientHeight;

          elem.style.height = this.modelValue
            ? elem.scrollHeight + offset + "px"
            : "";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.input {
  $self: &;

  width: 100%;
  padding-bottom: 24px;
  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  &__component {
    display: flex;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    align-items: center;
  }

  &__control {
    border: none;
    min-height: 56px;
    min-width: 100px;
    width: 100%;
    padding: 16px 16px 16px 24px;
    color: $white;
    font-size: 1rem;
    line-height: 1.3;
    resize: none;
    font-family: $sarabun;

    &.select {
      padding-left: 28px;
    }

    &:focus {
      outline: none;

      &::placeholder {
        color: transparent;
        transition: color 0.2s;
      }
    }

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      font-family: $Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.05em;
      opacity: 0.5;
      color: rgba($color: $white, $alpha: 0.5);
    }
  }

  &__error {
    padding: 5px 16px 0 16px;
    font-size: 14px;
    line-height: 1.2;
    color: $error-red;
    text-align: left;
  }

  &__label {
    position: absolute;
    left: 27px;
    font-size: 0.85rem;
    font-family: $sarabun;
    font-weight: $sarabunSemibold;
    line-height: 1.2;
    padding: 0 5px;
    opacity: 0;
    top: 20px;
    color: $white;
    transition: top 0.2s, opacity 0.2s 0.1s, background-color 0.2s;
  }

  &--error {
    #{$self}__label {
      top: -10px;
      opacity: 1;
    }

    #{$self}__control {
      border-color: $error-red;
    }
  }

  &--non-empty #{$self}__label,
  &__control:focus ~ #{$self}__label {
    top: -10px;
    opacity: 1;
    transition: top 0.2s 0.1s, opacity 0.3s, background-color 0.2s 0.1s;
  }
}
</style>
